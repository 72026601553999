body {
  font-family: "Montserrat";
  background:
    url("./assets/img/bg-nebulosa-morada-1.svg") no-repeat fixed,
    url("./assets/img/bg-nebulosa-morada-2.svg") no-repeat fixed,
    url("./assets/img/bg-nebulosa-cian-1.svg") no-repeat fixed,
    url("./assets/img/bg-starts.png") repeat,
    url("./assets/img/background.png") no-repeat center;
  background-size: auto, auto, auto, auto, cover;
  background-position:
    left 25rem,
    top right,
    top;
}

.nube-bg {
  height: 69rem;
  width: 100%;
  background-image: url("./assets/img/nube.svg");
  background-repeat: no-repeat;
  background-position-x: right;
}

body::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.main-tittle {
  font-size: 3.3rem;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  width: 1rem;
  height: 1rem;
  border: 0.2rem dashed #f3f3f3;
  /* Light grey */
  /* border-top: .2rem dashed #a3a3a3; */
  /* Blue */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.spinner-container {
  display: grid;
  justify-content: center;
  align-items: center;
}
.hover-trigger:hover .hover-target {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.hover-target {
  transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s;
  opacity: 0;
  visibility: hidden;
  transform: translateY(100%);
}
.tools-container {
  display: flex;
  flex-wrap: wrap; 
  justify-content: space-around; /* O space-between según prefieras */
  gap: 20px; /* Esto define el espacio entre los elementos */

 
}
.glass {
  background: rgba(255, 255, 255, 0.1); /* 
  backdrop-filter: blur(5px); /* Efecto de desenfoque para el vidrio */
  border-radius: 15px; /* Bordes redondeados para suavizar el contenedor */
  padding: 20px; /* Espaciado interno */
  margin: 20px;
}

.tool {
  flex: 1 0 20%; /* Esto podría ser ajustado si quieres cambiar el tamaño base de los contenedores */
  display: flex;
  flex-direction: column;
  align-items: center; /* Esto centrará las cosas horizontalmente en flex-direction: column */
  margin: 10px; /* Un margen uniforme en todos lados */
  max-width: 100%; /* Puedes establecer un ancho máximo */
  height: 100%; /* Y una altura fija si lo deseas */
}

.tool-logo {
  width: 100%; /* Esto hará que la imagen ocupe todo el ancho del contenedor */
  height: auto; /* Esto mantendrá la proporción de la imagen */
  max-height: 100%; /* Esto limitará la altura de la imagen */
  object-fit: contain; /* Esto asegurará que la imagen se ajuste dentro de las dimensiones sin distorsionarse */
  margin-bottom: 10px;
}


.tool-name {
  color: #fff; /* Ajustar según tu tema de color */
  font-size: 16px; /* Ajustar según necesidad */
}
/* Asumiendo que tienes una clase para el título, si no es así, puedes agregarla */
.title {
  text-align: center; /* Centra el texto horizontalmente */
  color: white; /* Cambia el color del texto a blanco */
  /* Otras propiedades de estilo que puedas necesitar, como tamaño de fuente, peso, etc. */
  font-size: 24px; /* Ejemplo de tamaño de fuente */
  font-weight: bold; /* Ejemplo de peso de fuente */
  margin-top: 30px; /* Ejemplo de margen superior */
  margin-bottom: 20px; /* Ejemplo de margen inferior */
}



.team-members-container {
  background: rgba(255, 255, 255, 0.1); /* Un fondo ligeramente transparente */
  backdrop-filter: blur(5px); /* Efecto de desenfoque para el vidrio */
  border-radius: 15px; /* Bordes redondeados para suavizar el contenedor */
  padding: 20px; /* Espaciado interno */
  margin: 20px; /* Margen externo */
}

.team-title {
  text-align: center;
  color: #fff;
  margin-bottom: 30px;
}

.members-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
}

.member {
  flex-basis: 20%; /* Ajustar según el tamaño deseado */
  text-align: center;
  margin: 10px;
}

.member-photo {
  width: 100px; /* Tamaño de la foto */
  height: 100px; /* Tamaño de la foto */
  border-radius: 50%; /* Hace la imagen circular */
  margin-bottom: 10px;
  object-fit: cover; /* Asegura que la imagen cubra todo el espacio */
}

.member-name {
  color: #e0e0e0; /* Color del título del miembro */
  font-size: 18px; /* Tamaño del título */
  margin-bottom: 5px;
}

.member-description {
  color: #c0c0c0; /* Color de la descripción */
  font-size: 14px; /* Tamaño de la descripción */
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Asegúrate que sea mayor que otros elementos */
}

.modal-container {
  padding: 20px;
  max-width: 100%;
  width: 100%;
/*  background: rgba(160, 252, 253, 0.1);  Un fondo ligeramente transparente */
 /* backdrop-filter: blur(5px);  Efecto de desenfoque para el vidrio */
  border-radius: 15px; /* Bordes redondeados para suavizar el contenedor */
  padding: 20px; /* Espaciado interno */
  margin: 20px; 
}

.modal-close-button {
  float: right;
  cursor: pointer;
}

/* Añadir más estilos según sea necesario */
